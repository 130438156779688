// This code initiates SPOTIM calls for the cluster articles
(function (window) {
    const { YAHOO, wafer } = window;
    // YAHOO.context is exposed via the kaizen-context component
    const { context } = YAHOO;
    const { device } = context;

    const FIRST_ARTICLE_COMMENTS_NODE_ID =
        'spotIm-conversations-module-wrapper';

    const comments = {};
    const uuidPartnerData = {};
    const extractYlk = (target) => {
        const ylk = {};
        const dataYlk = target.getAttribute('data-ylk') || '';
        let keyValArr = [];
        let val;

        dataYlk.split(';').forEach((keyVal) => {
            keyValArr = keyVal.split(':');
            val = keyValArr[1];
            if (typeof val !== 'undefined') {
                ylk[keyValArr[0]] = val;
            }
        });

        return ylk;
    };
    let spotImConfig;

    init();

    function init() {
        const spotimConfigElm: any = document.getElementById('spotim-config');
        const spotImElm: any =
            (spotimConfigElm &&
                wafer.utils.getConfigFromJSONScriptNode(spotimConfigElm)) ||
            {};
        if (spotImElm?.enabled) {
            spotImConfig = spotImElm;
            const setup = () => {
                wafer.on('caas:article:init', onArticleInit);
                wafer.on('caas:article:pre:inview', onArticleInView);
                wafer.on('caas:link:clicked', onArticleLinkClick);
            };

            if (wafer) {
                wafer.ready(function () {
                    setup();
                }, window);
            } else {
                document.body.addEventListener('wafer:ready', function () {
                    setup();
                });
            }
        }
    }

    function updateUUIDMap(event) {
        const partnerData = event.meta.data;
        const { uuid } = partnerData;

        if (uuid && !uuidPartnerData[uuid]) {
            // save partner data for the uuid in view so the link clicks can use them if needed
            // not all the data coming from `caas:article:pre:inview` event are present in
            // data coming from`caas:link:clicked`
            uuidPartnerData[uuid] = partnerData;

            const commentsNodeId = event.elem.getAttribute('data-comments-id')!;

            if (commentsNodeId) {
                uuidPartnerData[uuid].commentsNodeId = commentsNodeId;
            }
        }
    }

    function onArticleInit(event) {
        updateUUIDMap(event);
    }

    function onArticleInView(event) {
        updateUUIDMap(event);
        // comments are initialized only on comments cta click
        scrollToCommentsIfNeeded(event);
    }

    function scrollToCommentsIfNeeded(event) {
        const partnerData = event.meta.data;
        const { uuid } = partnerData;
        const commentsNodeId = uuidPartnerData[uuid].commentsNodeId;
        const caasShareSecCmtBtn = document.querySelector(
            `[data-comments-id=${commentsNodeId}] .caas-share-buttons .caas-comment`
        );
        const commentsNode = document.getElementById(commentsNodeId);

        if (!commentsNode || !caasShareSecCmtBtn) {
            return;
        }
        /**
         * Make sure it got scrolled correctly due to possible deferred modules which
         * can increase layout height and therefore interfere with needed scroll height.
         */
        const scrollEnsured = () => {
            // add support for both versions of view comment cta button
            const commentContainerNode = document.getElementById(
                `view-cmts-container-${uuid}`
            );
            const commentCTANode = document.getElementById(
                `view-cmts-cta-${uuid}`
            );
            const cmtNode = commentContainerNode;

            cmtNode && cmtNode.classList.add('showComments');

            commentCTANode && commentCTANode.classList.add('hide');

            const node = cmtNode || commentsNode;
            const scroll = () =>
                node.scrollIntoView({ behavior: 'smooth', block: 'center' });
            scroll();
            setTimeout(scroll, 1000);
        };

        const dataYlk: any = extractYlk(caasShareSecCmtBtn);
        const { sec = 'content-canvas', slk, pos = 0, ...opt_keys } = dataYlk;

        caasShareSecCmtBtn.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.rapidInstance.beaconClick(sec, slk, pos, opt_keys);
            scrollEnsured();
            initCommentsHandler(event, 'ON_CLICK');
        });
    }

    function onArticleLinkClick(event) {
        const partnerData = event.meta.data;
        const { type, uuid } = partnerData;

        // exit if the link clicked is not view comments CTA
        if ((type !== 'view-cmnts-cta' && type !== 'comment') || !uuid) {
            return;
        }
        // if the link clicked is view comments CTA, then show comments block
        const commentsWrapper = document.getElementById(
            `view-cmts-container-${uuid}`
        );

        if (commentsWrapper) {
            // @ts-ignore
            commentsWrapper.classList.add('showComments');
        }

        // init spotIm only on comments cta click
        initCommentsHandler(event, 'ON_CLICK');
    }

    function initCommentsHandler(event, eventType) {
        if (window.YAHOO) {
            const partnerData = event.meta.data;
            const uuid = partnerData.uuid;
            let viewCmtNode: HTMLElement;
            if (device === 'smartphone') {
                // don't add comments if comments is not allowed
                if (!partnerData.commentsAllowed) {
                    viewCmtNode = document.getElementById(
                        'spot-im-wafer-lightbox'
                    );

                    if (!viewCmtNode) {
                        return;
                    }
                    viewCmtNode.style.display = 'none';
                }
            } else {
                viewCmtNode = document.getElementById(`view-cmts-cta-${uuid}`);
                // for the cases, where we do not have any wrapper placed.
                if (!viewCmtNode) {
                    viewCmtNode =
                        document.getElementById(
                            `view-cmts-container-cmtCta-${uuid}`
                        ) ||
                        document.getElementById(`view-cmts-container-${uuid}`);
                }

                if (!viewCmtNode) {
                    return;
                }
            }

            // We've already loaded comments for this article or view comments cta is not available
            if (comments[uuid]) {
                return;
            }

            if (
                eventType === 'ON_VIEW' &&
                uuidPartnerData[uuid].commentsNodeId ===
                    FIRST_ARTICLE_COMMENTS_NODE_ID
            ) {
                // skip for the first article since its already initialized early
                return;
            }
            initComments(uuid);
        }
    }

    function initComments(uuid) {
        if (!uuid) {
            return;
        }
        const partnerData = uuidPartnerData[uuid] || {};
        // read current articles title and url if not found
        const title = partnerData.title || document.title;
        const url = partnerData.url || window.location.href;
        const commentsCount = partnerData.commentsCount || '';
        const adMeta = partnerData.adMeta || {};
        const { site_attribute, spaceid } = adMeta;
        const commentsNodeId =
            partnerData.commentsNodeId || FIRST_ARTICLE_COMMENTS_NODE_ID; // default to first article's comment node id
        const commentsNode = document.getElementById(commentsNodeId);

        if (!commentsNode) {
            return;
        }
        const { config = {} } = spotImConfig;
        const { wfDarlaConfig = {} } = config;
        const newConfig = {
            ...config,
            commentsCount,
            wfDarlaConfig: {
                ...wfDarlaConfig,
                siteAttribute: site_attribute,
                spaceid,
            },
        };
        const spotImParams = {
            ...newConfig,
            title: encodeURIComponent(title),
            url,
            uuid,
        };

        // firing event to initiate SpotIm (in JAC), which is being listened in spotIm JAC js file
        const params = {
            detail: {
                config: {
                    ...spotImParams,
                },
                elem: commentsNode,
            },
        };

        dispatchEvent(new CustomEvent('initSpotImComments', params));
        comments[uuid] = { commentsNode, commentsNodeId };
    }
})(window);
